<template>
  <a class="tile hover-white gray-250 " @click="handleTileClick(tile.title)" tabindex="0">
    <div class="tile_img">
      <img :src="iconPath" width="" :alt="tile.title" />
    </div>
    <div class="tile_content">
      <h2>{{ tile.title }}</h2>
      <p>
        {{ tile.description }}
      </p>
    </div>
  </a>
</template>

<script>
export default {
  name: "NewTile",
  props: ["tile", "page"],
  data: function() {
    return {
      iconPath: require(`../assets/images/tiles/${this.tile.icon}-icon.png`),
    };
  },
  methods: {
    // @TODO: PROBABLY TILE CLICKING DOESN'T WORK ON DEV PORTAL ANYMORE
    handleTileClick: function(tileName) {
      this.$router.push(`${this.page}/${tileName.replaceAll(" ", "-").toLowerCase()}`);
    },
  },
};
</script>

<style></style>
